import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { AppTypeUnion } from '@wc/wc-models/src';
import { Observable, timer } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import * as Enums from '../../../../../core/models/enums';
import { ConfirmationModalType } from '../../../../../core/models/enums';
import { EntityType, IncidentStatus } from '../../../../../core/models/gql.models';
import { SoundsService } from '../../../../../core/services/sounds.service';

export class ConfirmDialogModel {
  constructor(public type: Enums.ConfirmationModalType, public details?: string | undefined) {}
}

@Component({
  selector: 'wc-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnDestroy {
  message!: string;
  details: string | number;
  isTabletMode: boolean;
  modalType: ConfirmationModalType | undefined;
  confirmationModalTypes = ConfirmationModalType;
  incidentStatusEnum = IncidentStatus;
  entityTypeEnum = EntityType;
  timer$: Observable<string> | undefined = undefined;
  readonly viewMode: AppTypeUnion;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private soundService: SoundsService,
    @Inject(APP_TYPE_TOKEN) appType: AppTypeUnion
  ) {
    this.isTabletMode = appType === 'tablet';
    this.modalType = data.type;
    this.details = data.details;
    this.viewMode = appType;

    if ((data.type as ConfirmationModalType) === ConfirmationModalType.EditAboutToExpire) {
      this.timeCountDownInit();
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  soundAcknowledgement() {
    this.soundService.initiateSoundWithEmpty();
    this.onDismiss();
  }

  timeCountDownInit() {
    let expireSeconds = (this.details as number) * 60;

    this.timer$ = timer(0, 1000).pipe(
      map(() => {
        const min = Math.floor(expireSeconds / 60);
        const seconds = Math.floor(expireSeconds % 60);
        expireSeconds -= 1;

        return `${min < 10 ? '0' + min : min}:${seconds < 10 ? '0' + seconds : seconds}`;
      }),
      finalize(() => {
        this.onDismiss();
      })
    );
  }

  ngOnDestroy(): void {
    this.timer$ = undefined;
  }
}
