import { Injectable } from '@angular/core';
import { ReleaseNote } from '@wc/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';
import { ReleaseNotesApiService } from './release-notes.service-api';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  private unseenReleasesCountSubject = new BehaviorSubject(0);
  private releaseNotesSubject = new BehaviorSubject<ReleaseNote[]>([]);
  public releaseNotes$ = this.releaseNotesSubject.asObservable();
  public unseenReleasesCount$ = this.unseenReleasesCountSubject.asObservable();

  constructor(
    private releaseNotesApiService: ReleaseNotesApiService,
    private localStorageService: LocalStorageService
  ) {}

  fetchReleases() {
    this.releaseNotesApiService.getLatestReleaseNotes(3).subscribe(releaseNotes => {
      releaseNotes.sort(
        (a, b) => new Date(b.scheduleReleaseDate).getTime() - new Date(a.scheduleReleaseDate).getTime()
      );
      this.releaseNotesSubject.next(releaseNotes);
      this.initViewedReleases();
    });
  }

  initViewedReleases() {
    const ids = this.localStorageService.get(LocalStorageKeys.ViewedReleaseNoteIds) || [];
    const unViewedReleaseIds: number[] = this.releaseNotesSubject.value.map(r => r.id).filter(id => !ids.includes(id));
    this.unseenReleasesCountSubject.next(unViewedReleaseIds.length);
  }

  updateViewedReleaseIds() {
    this.localStorageService.set(
      LocalStorageKeys.ViewedReleaseNoteIds,
      this.releaseNotesSubject.value.map(r => r.id) || []
    );
    this.unseenReleasesCountSubject.next(0);
  }
}
