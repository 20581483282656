import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSoundFileTypes, AppSounds } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class SoundsService {
  private isSoundNotAllowed = new Subject<boolean>();
  private playSoundCounter = 0;
  isSoundNotAllowed$ = this.isSoundNotAllowed.asObservable();
  shouldPlaySound = false;
  alertSound;
  soundAlertEl = new Audio();

  initiateSoundWithEmpty() {
    /* @description used mainly for Safari on top for IOS
     * Seems that Safari and IOS need the same Audio ref, that was triggered by a click form the user
     * while Android is more flexible in the sense a global interaction is enough.
     * Thats the reason why we use the same Audio ref and changing the src.
     */
    this.soundAlertEl.src = `assets/sounds/${AppSounds.empty}.${AppSoundFileTypes.mp3}`;
    this.soundAlertEl?.play();
  }

  playSound(soundName: AppSounds, soundRepeats = 1, type: AppSoundFileTypes = AppSoundFileTypes.wav): void {
    this.soundAlertEl.src = `assets/sounds/${soundName}.${type}`;
    if (!this.shouldPlaySound) {
      this.playSoundCounter = 0;
      return;
    }
    this.playSoundCounter += soundRepeats;
    if (!(this.playSoundCounter > soundRepeats)) {
      this.triggerSoundProcess();
    }
  }

  private triggerSoundProcess(time = 1300) {
    this.playSoundWhileCounter(time);
  }

  private playSoundWhileCounter(time: number) {
    if (this.playSoundCounter > 0) {
      --this.playSoundCounter;
      setTimeout(() => {
        this.soundAlertEl?.play();
        console.log('playSoundWhileCounter');

        this.playSoundWhileCounter(time);
      }, time);
      return;
    }
  }

  checkIfSoundAllowedInSettings() {
    const audio = new Audio(`../assets/sounds/${AppSounds.empty}.${AppSoundFileTypes.mp3}`);
    audio.play().catch(() => this.isSoundNotAllowed.next(true));
  }
}

export enum SoundAlertLocalStorageKeys {
  AllowAlert = 'allow-alert',
}
