import { Injectable } from '@angular/core';
import { LiveMapService } from '@wc-core';
import { IncidentImpactedCorridor, IncidentImpactedSegment } from '@wc/core/models';
import {
  CustomStyleContextEnum,
  FeaturesPropsByLayerName,
  generateFeatureID,
  wcFeatureProperties,
  WcGeometryEnum,
} from '@wc/wc-map-viewer';
import { EntityStyleStatusEnum, LiveMapLayerNames } from '@wc/wc-models';
import { uniq } from 'lodash';
import { merge, Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';

export const hiddenCorridorNames = new Set(['NO_DATA', 'NO_IMPACT', 'NO_ASSOCIATED_CORRIDOR']);

export const COLOR_PER_LEVEL = {
  noImpact: '#06AF7C',
  lowImpact: '#06AF7C',
  mediumImpact: '#FA8315',
  highImpact: '#FF434C',
  noData: '#7A929C',
};

type ViewedIncidentInsight = {
  incidentId: number;
  timestamp: number;
  showOnMap: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class InsightService {
  visibleFeaturesIds: string[] = [];
  isAlive = true;
  private isShowOnMapSub$ = new ReplaySubject<boolean>(1);
  hoveredSegment$: Observable<string | undefined> = this.isShowOnMapSub$.pipe(
    switchMap(v => (v ? merge(this.segmentHovered$(), this.leaveEvent$()) : of(undefined))),
    distinctUntilChanged()
  );

  private segmentHovered$() {
    return this.liveMapService.hoverEvent$.pipe(
      map(e => {
        const feature = e.targets && e.targets[0] ? (e.targets[0].fProps as wcFeatureProperties) : undefined;
        return feature;
      }),
      debounceTime(10),
      filter((feature): feature is wcFeatureProperties =>
        feature?.entityType === LiveMapLayerNames.miscellanies.segment_line ? true : false
      ),
      map(feature => feature.id as string)
    );
  }

  private leaveEvent$() {
    return this.liveMapService.leaveEvent$.pipe(map(() => undefined));
  }

  constructor(private liveMapService: LiveMapService, private localStorageService: LocalStorageService) {}

  addSegmentsToMap(corridors: IncidentImpactedCorridor[]) {
    this.liveMapService.isMapReady$.pipe(take(1)).subscribe(() => {
      const flatSegments: IncidentImpactedSegment[] = uniq(
        corridors.flatMap((corridor: IncidentImpactedCorridor) => corridor.impactedSegments)
      );
      if (flatSegments.every(s => s.forecastDecrease === -1)) return;

      const flatSegmentsSorted = flatSegments.sort((a, b) => {
        if (a.forecastDecrease === -1 && b.forecastDecrease !== -1) {
          return -1;
        } else if (a.forecastDecrease !== -1 && b.forecastDecrease === -1) {
          return 1;
        } else {
          return a.forecastDecrease - b.forecastDecrease;
        }
      });
      const segmentLayer: FeaturesPropsByLayerName<string> = this.toFeaturesPropsByLayerName(flatSegmentsSorted);
      this.visibleFeaturesIds = flatSegmentsSorted.map(id => generateFeatureID('segment_line', id.segmentId));
      this.liveMapService.handleModifiedData(segmentLayer);
      this.isShowOnMapSub$.next(true);
    });
  }

  removeSegmentsFromMap() {
    this.isShowOnMapSub$.next(false);
    this.liveMapService.handleRemovedData(this.visibleFeaturesIds);
  }

  highlightCorridorSegmentFeatures(corridor?: IncidentImpactedCorridor) {
    const ids = corridor?.impactedSegments.map(s => s.segmentId) || [];

    this.liveMapService.setFeaturesWithStyleContext(
      LiveMapLayerNames.miscellanies.segment_line,
      ids,
      CustomStyleContextEnum.highlight
    );
  }

  getInsightHexColor(percentage: number) {
    if (percentage === -1) return COLOR_PER_LEVEL.noData;
    if (percentage < 20) return COLOR_PER_LEVEL.noImpact;
    if (percentage >= 20 && percentage <= 35) return COLOR_PER_LEVEL.lowImpact;
    if (percentage > 35 && percentage <= 60) return COLOR_PER_LEVEL.mediumImpact;
    return COLOR_PER_LEVEL.highImpact;
  }

  private toFeaturesPropsByLayerName(segments: IncidentImpactedSegment[]): {
    segment_line: wcFeatureProperties<string>[];
  } {
    const wcFeatureProps = segments.map<wcFeatureProperties<string>>(segment => ({
      id: segment.segmentId,
      coordinates: segment.linestring.coordinates,
      color: this.getInsightHexColor(segment.forecastDecrease),
      geomType: WcGeometryEnum.LINE_STRING,
      entitySubType: 'segment_line',
      entityType: 'segment_line',
      parentLayerName: 'segment_line',
      isVisible: true,
      entityStatusForStyle: EntityStyleStatusEnum.default,
      styleKey: LiveMapLayerNames.miscellanies.segment_line,
    }));

    return { segment_line: wcFeatureProps };
  }
}
