import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { GetEmbedDashboardUrlGQL } from "@wc/core";
import { CustomRxOperatorsService } from "@wc/wc-core/src";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsApiService {

  constructor(
    private getEmbedDashboardUrlGQL: GetEmbedDashboardUrlGQL,
    private customOperators: CustomRxOperatorsService,
  ) { }

  getAnalyticsReportUrl(dashboardId: string) {
    return this.getEmbedDashboardUrlGQL.fetch({ dashboardId }).pipe(
      map(res => res?.data.getEmbedDashboardUrl.dashboardUrl),
      this.customOperators.catchGqlErrors()
    );
  }
}
